<template>
  <div>
    <b-card class="filters-card mb-2">
      <b-row>
        <b-col cols="12" sm="6" class="mb-2">
          <b-form-group :label="$t('Data Inicial')" label-for="start-date-picker">
            <b-form-datepicker id="start-date-picker" v-model="startDate" @input="applyFilters" :value-as-date="true"
              :locale="dateLanguage">
            </b-form-datepicker>
          </b-form-group>
        </b-col>

        <b-col cols="12" sm="6" class="mb-2">
          <b-form-group :label="$t('Data Final')" label-for="end-date-picker">
            <b-form-datepicker id="end-date-picker" v-model="endDate" @input="applyFilters" :value-as-date="true"
              :locale="dateLanguage">
            </b-form-datepicker>
          </b-form-group>
        </b-col>

        <b-col cols="12" class="mb-2">
          <b-tabs v-model="selectedBonusType" @input="applyFilters" class="bonus-tabs">
            <b-tab :title="$t('Todos')" :title-link-class="{ 'active-tab': selectedBonusType === 0 }" value="0"></b-tab>
            <b-tab :title="$t('Residual')" :title-link-class="{ 'active-tab': selectedBonusType === 1 }"
              value="1"></b-tab>
            <b-tab :title="$t('Venda Direta')" :title-link-class="{ 'active-tab': selectedBonusType === 2 }"
              value="2"></b-tab>
            <b-tab :title="$t('Venda Indireta')" :title-link-class="{ 'active-tab': selectedBonusType === 3 }"
              value="3"></b-tab>
            <b-tab :title="$t('Binário')" :title-link-class="{ 'active-tab': selectedBonusType === 4 }"
              value="4"></b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>


    <div class="stat-cards mb-2">
      <b-row>
        <b-col md="4">
          <b-card class="stat-card">
            <h5>{{ $t('Total de Bônus') }}</h5>
            <p class="stat-value text-primary">
              <span v-if="isLoading">
                <b-spinner small type="grow"></b-spinner>
              </span>
              <span v-else>
                {{ moneyFormat(totalBonuses) }}
              </span>
            </p>
            <p class="stat-records text-muted">
              <small>{{ isLoading ? '?' : totalBonusRecords }} {{ $t('registros') }}</small>
            </p>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card class="stat-card">
            <h5>{{ $t('Total de Bônus Residual') }}</h5>
            <p class="stat-value text-primary">
              <span v-if="isLoading">
                <b-spinner small type="grow"></b-spinner>
              </span>
              <span v-else>
                {{ moneyFormat(totalResidualBonuses) }}
              </span>
            </p>
            <p class="stat-records text-muted">
              <small>{{ isLoading ? '?' : bonusTotals.residual.total }} {{ $t('registros') }}</small>
            </p>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card class="stat-card">
            <h5>{{ $t('Total de Bônus Binário') }}</h5>
            <p class="stat-value text-primary">
              <span v-if="isLoading">
                <b-spinner small type="grow"></b-spinner>
              </span>
              <span v-else>
                {{ moneyFormat(totalBinaryBonuses) }}
              </span>
            </p>
            <p class="stat-records text-muted">
              <small>{{ isLoading ? '?' : bonusTotals.binary.total }} {{ $t('registros') }}</small>
            </p>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card class="stat-card">
            <h5>{{ $t('Total de Venda Direta') }}</h5>
            <p class="stat-value text-primary">
              <span v-if="isLoading">
                <b-spinner small type="grow"></b-spinner>
              </span>
              <span v-else>
                {{ moneyFormat(totalDirectSellingBonuses) }}
              </span>
            </p>
            <p class="stat-records text-muted">
              <small>{{ isLoading ? '?' : bonusTotals.direct_selling.total }} {{ $t('registros') }}</small>
            </p>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card class="stat-card">
            <h5>{{ $t('Total de Venda Indireta') }}</h5>
            <p class="stat-value text-primary">
              <span v-if="isLoading">
                <b-spinner small type="grow"></b-spinner>
              </span>
              <span v-else>
                {{ moneyFormat(totalIndirectSellingBonuses) }}
              </span>
            </p>
            <p class="stat-records text-muted">
              <small>{{ isLoading ? '?' : bonusTotals.indirect_selling.total }} {{ $t('registros') }}</small>
            </p>
          </b-card>
        </b-col>
      </b-row>
    </div>


    <b-card :title="$t('Histórico de bônus')" class="bonus-card mb-0">
      <div v-if="isLoading" class="d-flex justify-content-center py-4">
        <b-spinner label="Carregando..." variant="primary"></b-spinner>
      </div>
      <div v-else>
        <app-timeline>
          <app-timeline-item v-for="historic in filteredBonusHistorical" :key="historic._id"
            class="timeline-item card-item">
            <div class="card-content">
              <div
                class="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center mb-0 w-100">
                <div class="d-flex flex-column align-items-start">
                  <span class="text-success font-weight-bold value-text">
                    {{ historic.value < 0.01 ? moneyFormat4Dec(historic.value) : moneyFormat(historic.value) }} </span>
                      <span v-if="historic.user" class="text-primary font-weight-bold user-name mt-1">
                        {{ formatName(historic.user.fullname) }}
                      </span>
                </div>
                <div class="text-muted date-text mt-2 mt-sm-0" style="font-size: 0.9rem;">
                  {{ formatDate(historic.createdAt) }}
                </div>
              </div>
              <div class="mt-2">
                <span class="text-muted description-text">
                  {{ $t(historic.title) }}
                </span>
              </div>
            </div>
          </app-timeline-item>
          <div v-if="filteredBonusHistorical.length === 0" class="text-center">
            {{ $t("Você ainda não recebeu bônus de rede") }}
          </div>
          <div class="d-flex justify-content-center mt-2" v-if="totalBonusRecords > limit" @click="seeMore">
            <b-button variant="primary">{{ $t('Ver mais') }}</b-button>
          </div>
        </app-timeline>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BFormDatepicker,
  BTabs,
  BTab,
  BRow,
  BCol,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import { mapGetters } from "vuex";
import BonusCard from "./BonusCard.vue";
import { debounce } from "debounce";

export default {
  components: {
    BCard,
    BFormGroup,
    BFormDatepicker,
    BTabs,
    BTab,
    BRow,
    BCol,
    AppTimeline,
    AppTimelineItem,
    BonusCard
  },
  data() {
    const now = new Date();
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    return {
      startDate: firstDay,
      endDate: lastDay,
      selectedBonusType: 0,
      bonusHistorical: [],
      filteredBonusHistorical: [],
      bonusTypeMap: {
        0: "all",
        1: "residual",
        2: "direct_selling",
        3: "indirect_selling",
        4: "binary"
      },
      bonusTotals: {
        residual: { total: 0, totalValue: 0 },
        binary: { total: 0, totalValue: 0 },
        direct_selling: { total: 0, totalValue: 0 },
        indirect_selling: { total: 0, totalValue: 0 },
      },
      totalBonuses: 0,
      totalBonusRecords: 0,
      totalResidualBonuses: 0,
      totalBinaryBonuses: 0,
      totalDirectSellingBonuses: 0,
      totalIndirectSellingBonuses: 0,
      isLoading: false,
      dateLanguage: 'en',
      limit: 25
    };
  },
  computed: {
    ...mapGetters(["returnLocale"]),
  },
  mounted() {
    this.fetchBonusHistorical();
  },
  methods: {
    seeMore() {
      this.limit += 25;
    },
    async fetchBonusHistorical() {
      const startDate = this.startDate;
      const endDate = this.endDate;
      const selectedType = this.bonusTypeMap[this.selectedBonusType];
      const adjustedStartDate = new Date(startDate);
      const adjustedEndDate = new Date(endDate);
      adjustedStartDate.setHours(0, 0, 0, 0);
      adjustedEndDate.setHours(23, 59, 59, 999);

      const params = {
        startDate: adjustedStartDate.toISOString(),
        endDate: adjustedEndDate.toISOString(),
        bonusType: selectedType,
        limit: this.limit
      };

      this.isLoading = true;

      try {
        const resp = await this.$store.dispatch('fetchAdminBonusHistorical', params);
        this.bonusHistorical = resp.logBonusUser;
        this.bonusTotals = resp.bonusTotals;
        this.filteredBonusHistorical = [...this.bonusHistorical];
        this.calculateTotals();
        this.isLoading = false;
      } catch (error) {
        console.error('Erro ao buscar histórico de bônus:', error);
      }
    },
    debouncedApplyFilters: debounce(function () {
      this.applyFilters();
    }, 500),
    applyFilters() {
      this.fetchBonusHistorical();
    },
    calculateTotals() {
      this.totalBonuses = Object.values(this.bonusTotals).reduce((sum, bonus) => sum + bonus.totalValue, 0);
      this.totalBonusRecords = Object.values(this.bonusTotals).reduce((sum, bonus) => sum + bonus.total, 0);
      this.totalResidualBonuses = this.bonusTotals.residual.totalValue || 0;
      this.totalBinaryBonuses = this.bonusTotals.binary.totalValue || 0;
      this.totalDirectSellingBonuses = this.bonusTotals.direct_selling.totalValue || 0;
      this.totalIndirectSellingBonuses = this.bonusTotals.indirect_selling.totalValue || 0;
    },
    formatName(name) {
      return name
        .toLowerCase()
        .replace(/\b\w/g, function (c) {
          return c.toUpperCase();
        });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const formattedDate = date.toLocaleDateString('pt-BR');
      const formattedTime = date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
      return `${formattedDate} ${formattedTime}`;
    },
  },
  watch: {
    returnLocale: function (oldVal, newVal) {
      this.dateLanguage = oldVal;
    },
    startDate() {
      this.fetchBonusHistorical();
    },
    endDate() {
      this.fetchBonusHistorical();
    },
    selectedBonusType() {
      this.fetchBonusHistorical();
    },
    limit() {
      this.fetchBonusHistorical();
    },
  },
};
</script>

<style scoped>
.active-tab {
  background-color: #17a2b8 !important;
  color: #fff !important;
}

.stat-cards {
  margin-bottom: 15px;
}

.stat-card {
  color: #333;
  text-align: center;
  border: 1px solid #dee2e6;
}

.stat-value {
  font-size: 1.5rem;
  font-weight: bold;
}

.bonus-card {
  color: #333;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  margin-top: -1rem;
}

.card-item {
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 12px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.value-text {
  font-size: 1.2rem;
  color: #28a745;
  font-weight: bold;
}

.user-name {
  color: #007bff;
}

.date-text {
  color: #C5C9CD !important;
}

.description-text {
  color: #C5C9CD !important;
}

.card-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  background-color: #161D31;
}

@media (max-width: 576px) {
  .card-content {
    align-items: stretch;
  }

  .d-flex.flex-column.flex-sm-row {
    flex-direction: column !important;
  }

  .date-text {
    text-align: left;
    margin-top: 10px;
  }

  .value-text,
  .user-name {
    font-size: 1.2rem;
  }

  .description-text {
    font-size: 0.95rem;
  }

  .card-item {
    padding: 10px;
  }

  .stat-records {
    margin-top: -7px;
  }

}
</style>
